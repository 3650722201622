import React from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Menu from '../components/menu';
import Layout from "../components/layout";

const spanStyle = {
  'display': 'block',
  'padding': '20px'
}

export default () => {
	return (
		<Layout>
      <div className="jumbotron">
    		<Link to="/" className="closeX"><span>X</span></Link>
        <Menu localPath="/print" />
        <h1>Druck</h1>
        <p className="jumbotron-text">
         Da meine (insbesondere die vor 2020 veröffentlichten) Druckdateien den o.g. Standards nicht entsprechen, gebe ich im Folgenden eine Übersicht über die von mir bisher beauftragten Druckereien sowie die dabei aufgetretenen Probleme:
        </p>
      </div>
      <div className="colorBox">
        <h3>Worauf Sie achten sollten...</h3>
        <p>Über Jahre hatte ich keine Probleme mit den hier bereitgestellten Druckdateien für meine OpenBooks in der Online-Druckerei Wir-Machen-Druck.de (= WMD). WMD hat ein sehr gutes Preis-Leistungs-Verhältnis und Reklamationen wurden bis vor Kurzem auch zügig bearbeitet. Die Reklamation einer fehlerhaft gedruckten Abbildung in meinem OpenBook <i>Filmmusik</i> allerdings wurde im Oktober 2020 abgelehnt, weil meinen Druckdateien nicht den PDF-Standards PDF/X-1a:2001 oder X3:2002 entsprechen. Die Antwort auf meine Reklamation lautete:
          <span style={spanStyle}>
           »<i>Sie haben Ihre Daten in der PDF Version 1.4 angelegt. Alle PDF Versionen über 1.3 können Transparenzen enthalten (erzeugt durch Effekte wie z.B. Deckkraft, Schlagschatten, weiche Kanten usw.). Diese müssen zum Druck zunächst reduziert, verflacht werden. Hierbei kann es leider zu Umrechnungsfehlern im RIP (Raster Image Processor) kommen. Wie in der Druckbranche üblich, arbeiten auch wir auf Basis von PDF/X-1a:2001 oder X3:2002, also mit der Kompatibilität Acrobat 4 (PDF 1.3), welche keine offenen Transparenzen unterstützt. Im Regelfall müssen in der Datei enthaltene Transparenzen bereits beim PDF Export korrekt verflacht werden. Wie die offenen Transparenzen im RIP umgerechnet werden, können wir leider nicht beeinflussen. Hierfür übernehmen wir keine Gewähr.</i>«
          </span>
          <b>Da Druckdaten nach den genannten Standards auf der Webseite von WMD zwar empfohlen, jedoch nicht gefordert werden, heißt diese Auskunft im Klartext: WMD garantiert Ihnen bei der Verwendung der hier vor 2020 bereit gestellten OpenBook-Druckdateien keinen fehlerfreien Druck!</b> Fehldrucke sind Ihr Pech oder werden − wenn Sie Glück haben − auf Kulanz erstattet.
         </p>
       </div>
       <div className="gridBox">
        <div className="gridFraction">
          <a href="https://www.viaprinto.de"><img src="https://www.viaprinto.de/viaprintoweb/dotcms_themes/productpage/images/viaprinto-logo-claim.svg" /></a>
        </div>
        <div className="gridFraction">
          Die Drucke vo Viaprinto hatten immer eine sehr gute Qualität, allerdings ist diese Online-Druckerei im Vergleich sehr teuer (ein Exemplar des OpenBooks Filmmusik hätte am 14.10.2020 immerhin stolze 27,69 € gekostet) und damit für Privatanwender nur bedingt geeignet.
        </div>
        <div className="gridFraction">
          <a href="https://www.wir-machen-druck.de/"><img src="https://www.wir-machen-druck.de/tpl/manns-partner/section_template/images/logo.png" /></a>
        </div>
        <div className="gridFraction">
          Die Drucke von WMD sind demgegenüber sehr günstig und in der Regel auch gut (ein Exemplar des OpenBooks Filmmusik kostete am 22.09.2020 nur 5,80 €). Vor dem Oktober 2020 wurden Fehldrucke meiner Aufträgen anstandslos ersetzt, spätestens seit Oktober 2020 übernimmt WMD allerdings für ein korrektes Druckergebnis meiner Druckdateien keine Gewähr mehr (s.o.).
        </div>
        <div className="gridFraction">
          <a href="https://www.saxoprint.de/"><img src="https://www.saxoprint.de/Librarys/test/logo.svg" /></a>
        </div>
        <div className="gridFraction">
          <div>Die Drucke von Saxoprint sind vergleichbar günstig wie die Drucke von WMD (ein Exemplar des OpenBooks Filmmusik kostete am 14.10.2020 sogar nur 5,74 €). Die Qualität der Drucke ist mit der von WMD vergleichbar (das heißt: beide Online-Druckereien haben ein sehr gutes Preis-Leistungs-Verhältnis). Das Exemplar des OpenBooks Filmmusik, das von WMD wegen der Transparenzen nicht fehlerfrei gedruckt werden konnte (einige SVG-Grafiken fehlten, s.o.), konnte bei Saxoprint allerdings fehlerfrei gedruckt werden (Saxoprint kann mit dem <a href="https://www.saxoprint.de/druckdatenanleitung" target="_blank">PDF-Standard 1.4 umgehen bzw. Transaprenzen drucken</a>). Meine persönlichen Bestellungen werde ich daher in Zukunft bei Saxoprint in Auftrag geben.</div>
        </div>
      </div>

      <hr />

      <h2>Druckdateien zum Download (mit Anschitt, jedoch ohne Gewähr)</h2>
      <h3>Unterrichtshefte für allgemeinbildende Schulen</h3>
      <ul className="printDownloadList">
        <li>
          <a href="/data/openbooks/PrintDownloadFiles/Kaiser_BachUnterrichtsheft_2016-10-07_mAnschnitt.pdf">
            <i>Ulrich Kaiser, Johann Sebastian Bach. Ein Superstar gestern und heute (= OpenBook 2), Unterrichtsheft, Karlsfeld 2011.</i>
          </a>
        </li>
        <li>
          <a href="/data/openbooks/PrintDownloadFiles/Kaiser_BachKommentarheft_2016-11-07_optimiert-wmd.pdf">
            <i>Ulrich Kaiser, Johann Sebastian Bach. Ein Superstar gestern und heute (= OpenBook 2), Kommentarheft, Karlsfeld 2011.</i>
          </a>
        </li>
        <li>
          <a href="/data/openbooks/PrintDownloadFiles/kaiser_filmmusik_2020-10-17_mAnschnitt.pdf">
            <i>Ulrich Kaiser, Filmmusik (= OpenBook 15), Karlsfeld 2020.</i>
          </a>
        </li>
        <li>
          <a href="/data/openbooks/PrintDownloadFiles/kaiser_formenlehre_2019-03-16_mA.pdf">
            <i>Ulrich Kaiser, Formenlehre der Musik, Karlsfeld 2019.</i>
          </a>
        </li>
        <li>
          <a href="/data/openbooks/PrintDownloadFiles/Kaiser_KostenloseSoftware_release_2016-07-26-mAnschnitt.pdf">
            <i>Ulrich Kaiser, Kostenlose Software für den Musikunterricht (= OpenBook 7), Arbeitsheft, inkl. Materialien, Software und Videos, Karlsfeld 2014, 2. korr. Aufl. 2016.</i>
          </a>
        </li>
        <li>
          <a href="/data/openbooks/PrintDownloadFiles/Kaiser_trInstrumente_2016-10-07_release-mAnschnitt.pdf">
            <i>Ulrich Kaiser, Orchesterinstrumente. Transponierende Instrumente (= OpenBook 16), Karlsfeld 2016.</i>
          </a>
        </li>     
      </ul>

       <h3>Musikhefte für allgemeinbildende Schulen</h3>
      <ul className="printDownloadList">        
        <li>
          <a href="/data/openbooks/PrintDownloadFiles/Unterrichtsheft_BandImpulse-1_ThatImage_2015-06-16-mA.pdf">
            <i>Ulrich Kaiser, Band-Impulse. Heft 1: That Image (= OpenBook 9 / Heft 1), Karlsfeld 2015.</i>
          </a>
        </li>
        <li>
          <a href="/data/openbooks/PrintDownloadFiles/Unterrichtsheft_BandImpulse-2_EliseInBlack_2015-09-21-mA.pdf">
            <i>Ulrich Kaiser, Band-Impulse. Heft 2: Elise In Black (= OpenBook 9 / Heft 2), Karlsfeld 2015.</i>
          </a>
        </li>       
        <li>
          <a href="/data/openbooks/PrintDownloadFiles/Unterrichtsheft_BandImpulse-3_PatternStyles1_2015-12-17-mA.pdf">
            <i>Ulrich Kaiser, Band-Impulse. Heft 3: Pattern und Styles 1 (Am-Em/E7-A7/Rap/Pop) (= OpenBook 9 / Heft 3), Karlsfeld 2015.</i>
          </a>
        </li>
         <li>
          <a href="/data/openbooks/PrintDownloadFiles/Unterrichtsheft_BandImpulse-4_Musiklehre1_2016-02-26-mA.pdf">
            <i>Ulrich Kaiser, Band-Impulse. Heft 4: Gehörbildung und Musiklehre. Übungen in Dur (= OpenBook 9 / Heft 4), Karlsfeld 2016.</i>
          </a>
        </li>
        <li>
          <a href="/data/openbooks/PrintDownloadFiles/kaiser_brahms5_2017-02-18_mA.pdf">
            <i>Johannes Brahms. Ungarischer Tanz Nr. 5 arrangiert und mit einer Einleitung versehen von Ulrich Kaiser (= OpenBook 18/1), Karlsfeld 2017.</i>
          </a>
        </li>
        <li>
          <a href="/data/openbooks/PrintDownloadFiles/kaiser_carmensuite_2017-09-29_mA.pdf">
            <i>George Bizet. Carmen zu einer Suite arrangiert von Ulrich Kaiser (= OpenBook 18/2), Karlsfeld 2017.</i>
          </a>
        </li>
      </ul>

      <h3>Musikhefte für Musikschulen</h3>
      <ul className="printDownloadList">
        <li>
          <a href="/data/openbooks/PrintDownloadFiles/Unterrichtsheft-InventionenPlusOne_2015-12-12-mA.pdf">
            <i>Ulrich Kaiser, Inventionen plus One. Kammermusik mit Johann Sebastian Bach (= OpenBook 11), Karlsfeld 2015.</i>
          </a>
        </li>
        <li>
          <a href="/data/openbooks/PrintDownloadFiles/kaiser_happyforChoir_2017-04-06_mAnschnitt.pdf">
            <i>Kanon und 8 Variationen über Happy Birthday To You (= OpenBook 14/2), Karlsfeld 2017.</i>
          </a>
        </li>       
        <li>
          <a href="/data/openbooks/PrintDownloadFiles/Kaiser_HarmonieUndForm_release_2015-08-01-mAnschnitt.pdf">
            <i>Ulrich Kaiser, Harmonielehre. Harmonie und Form für Kompositionen in Dur (= OpenBook 8), Karlsfeld 2015.</i>
          </a>
        </li>
        <li>
          <a href="/data/openbooks/PrintDownloadFiles/Unterrichtsheft-InventionenPlusOne_2015-12-12-mA.pdf">
            <i>Ulrich Kaiser, Inventionen plus One. Kammermusik mit Johann Sebastian Bach (= OpenBook 11), Karlsfeld 2015.</i>
          </a>
        </li>
        <li>
          <a href="/data/openbooks/PrintDownloadFiles/Kaiser_HarmonielehreReloaded_release_2016-07-24-mAnschnitt.pdf">
            <i>Ulrich Kaiser, Harmonielehre #reloaded. Eine praktische Anleitung für Musikerinnen und Musiker (= OpenBook 12), Karlsfeld 2016.</i>
          </a>
        </li>
        <li>
          <a href="/data/openbooks/PrintDownloadFiles/kaiser_brahms5_2017-02-18_mA.pdf">
            <i>Johannes Brahms. Ungarischer Tanz Nr. 5 arrangiert und mit einer Einleitung versehen von Ulrich Kaiser (= OpenBook 18/1), Karlsfeld 2017.</i>
          </a>
        </li>
        <li>
          <a href="/data/openbooks/PrintDownloadFiles/kaiser_carmensuite_2017-09-29_mA.pdf">
            <i>George Bizet. Carmen zu einer Suite arrangiert von Ulrich Kaiser (= OpenBook 18/2), Karlsfeld 2017.</i>
          </a>
        </li>        
        <li>
          <a href="/data/openbooks/PrintDownloadFiles/kaiser_brahms-partitur-vlvl-mA.pdf">
            <i>J. Brahms. Walzer aus Op. 39 arrangiert für 2 Violinen von Ulrich Kaiser (= OpenBook 17/1), Karlsfeld 2021.</i>
          </a>
        </li>
      </ul>

      <h3>Musikhefte für Grundschulen und Kindergärten</h3>
      <ul className="printDownloadList">       
        <li>
          <a href="/data/openbooks/PrintDownloadFiles/Kinderhaus-Musikheft_2015-07-12-mA.pdf">
            <i>Ulrich Kaiser, Das Kinderhauslied (= OpenBook 10), Karlsfeld 2015.</i>
          </a>
        </li>        
      </ul>
      <Helmet>
        <title>OER | Ulrich Kaiser - Print</title>
        <meta name="description" content="Hier finden Sie Informationen zum Druck sowie Druckdateien zu den OpenBooks (OER) von Ulrich Kaiser." />
        <meta name="keywords" content="Druck, Druckdateien, OER, Open Educational Resources, OpenBooks" />
        <meta name="copyright" content="Ulrich Kaiser, 2011" />
      </Helmet>

    </Layout>
  )
}